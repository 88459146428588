import * as Sentry from '@sentry/nextjs';
import { usePostHog } from 'posthog-js/react';

const LIFETIME_VALUE_MULTIPLIER_MONTHLY = 24;
const LIFETIME_VALUE_MULTIPLIER_YEARLY = 2;
const POSTHOG_DONATION_EVENT_RECURRING = 'premium:subscription_start';
const POSTHOG_DONATION_EVENT_ONETIME = 'coins:purchase';
const POSTHOG_SIGNUP_EVENT_FB_MESSENGER = 'signup:messenger';
const POSTHOG_SIGNUP_EVENT_IMESSAGE = 'signup:apple_messages';
const POSTHOG_SIGNUP_EVENT_INSTA = 'signup:instagram';
const POSTHOG_SIGNUP_EVENT_SMS = 'signup:sms';
const POSTHOG_SIGNUP_EVENT_TELEGRAM = 'signup:telegram';

export const useAnalytics = () => {
  const posthog = usePostHog();

  const donationTrackEvent = ({
    recurringPeriod = null,
    donationValueCents,
  }: {
    recurringPeriod?: string | null;
    donationValueCents: number;
  }) => {
    if (recurringPeriod === 'MONTH') {
      posthog.capture(POSTHOG_DONATION_EVENT_RECURRING, {
        price: donationValueCents,
        period: 'monthly',
        value: donationValueCents * LIFETIME_VALUE_MULTIPLIER_MONTHLY,
      });
    } else if (recurringPeriod === 'YEAR') {
      posthog.capture(POSTHOG_DONATION_EVENT_RECURRING, {
        price: donationValueCents,
        period: 'yearly',
        value: donationValueCents * LIFETIME_VALUE_MULTIPLIER_YEARLY,
      });
    } else {
      posthog.capture(POSTHOG_DONATION_EVENT_ONETIME, {
        price: donationValueCents,
        value: donationValueCents,
      });
    }
  };

  const trackEvent = ({
    eventName,
    eventData = {},
  }: {
    eventName: string;
    eventData?: Record<string, unknown>;
  }) => {
    // Report bad inputs to sentry
    if (!eventName) {
      Sentry.addBreadcrumb({
        category: 'analytics',
        message: 'trackEvent: missing eventName',
        level: 'error',
        data: { eventName, eventData },
      });
      Sentry.captureException(new Error('trackEvent: missing eventName'));
    }
    posthog.capture(eventName, eventData);
  };

  return { donationTrackEvent, trackEvent };
};

export {
  POSTHOG_SIGNUP_EVENT_FB_MESSENGER,
  POSTHOG_SIGNUP_EVENT_IMESSAGE,
  POSTHOG_SIGNUP_EVENT_INSTA,
  POSTHOG_SIGNUP_EVENT_SMS,
  POSTHOG_SIGNUP_EVENT_TELEGRAM,
  POSTHOG_DONATION_EVENT_RECURRING,
  POSTHOG_DONATION_EVENT_ONETIME,
};
