import cx from 'classnames';
import PropTypes from 'prop-types';
import Img from 'next/image';
import shadow from 'images/bots/bot-shadow.svg';

const BotImage = ({ className = '', src }) => {
  return (
    <>
      <div className="flex flex-col items-center">
        <Img
          alt="Resistbot illustrated character"
          height="160"
          width="160"
          className={cx(className, 'animate-floating', 'h-40')}
          src={src}
        />
        <Img
          src={shadow}
          height="15"
          width="100"
          className={cx(className, 'animate-shadow', 'dark:animate-darkShadow')}
          alt="Shadow for Resistbot illustrated character"
        />
      </div>
    </>
  );
};

BotImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default BotImage;
