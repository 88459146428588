import PropTypes from 'prop-types';

import Container from 'components/container';
import HomeAnchorImage from 'components/header/HomeAnchorImage';
import Anchor from 'components/typography/anchor';

import config from 'siteconfig';

import { Nav } from './Nav';

const Header = ({ children = null, siteTitle }) => {
  return (
    <header className="min-h-16 border-b border-black/10 bg-transparent text-center dark:border-white/10 dark:bg-indigo-950/50 print:hidden">
      <div className="mb-2 w-full bg-pink-700 px-2 py-4 text-center text-sm text-white sm:text-base">
        <Anchor
          href={config.channelUrls.sms + 'resist'}
          className="underline underline-offset-4"
        >
          Text <strong>RESIST</strong> to 50409
        </Anchor>{' '}
        to get started, or{' '}
        <Anchor
          href={config.donateUrls.stripe}
          className="font-bold underline underline-offset-4"
        >
          donate
        </Anchor>{' '}
        to keep us&nbsp;online.
      </div>
      <div className="relative">
        <Anchor
          label="Resistbot Home"
          href="/"
          className="absolute left-4 top-4 h-16"
        >
          <HomeAnchorImage siteTitle={siteTitle} />
        </Anchor>
        <Nav />
        <Container>{children}</Container>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Header;
