// A mapping of where these images _should_ be stored, relative to the generated site root
const icon48 = '/favicons/icon-48x48.png';
const icon72 = '/favicons/icon-72x72.png';
const icon96 = '/favicons/icon-96x96.png';
const icon144 = '/favicons/icon-144x144.png';
const icon168 = '/favicons/icon-168x168.png';
const icon192 = '/favicons/icon-192x192.png';
const icon256 = '/favicons/icon-256x256.png';
const icon384 = '/favicons/icon-384x384.png';
const icon512 = '/favicons/icon-512x512.png';

export {
  icon48,
  icon72,
  icon96,
  icon144,
  icon168,
  icon192,
  icon256,
  icon384,
  icon512,
};
